import type {PrismicDocument} from '@prismicio/types';
import {createError, useLogger, usePrismicClient, useSpanWrap} from '#imports';

export async function usePrismicDocument(documentType: string, locale: string): Promise<PrismicDocument | null> {
    const logger = useLogger();
    const client = usePrismicClient();

    try {
        const getPrismicDocument = () => client.getSingle(documentType, {lang: locale});
        return await useSpanWrap(getPrismicDocument, {httpMethod: 'prismic.get', kind: 'prismic', name: `[prismic] ${documentType}`});
    } catch (err: any) {
        const message = `Could not retrieve ${documentType} document from Prismic for locale ${locale}. Error: name=${err.name}, message=${err.message}, url=${err.url}, response=${err.response}.`;
        logger.error(message);
        throw createError({message, statusCode: 404});
    }
}

// Code generated by Slice Machine. DO NOT EDIT.
// @ts-nocheck

import {defineAsyncComponent} from 'vue';
import {defineSliceZoneComponents} from '@prismicio/vue';

export const components = defineSliceZoneComponents({
    country_slice: defineAsyncComponent(() => import('./CountrySlice/index.vue')),
    header_links_slice: defineAsyncComponent(() => import('./HeaderLinksSlice/index.vue')),
    header_search_slice: defineAsyncComponent(() => import('./HeaderSearchSlice/index.vue')),
    header_user_slice: defineAsyncComponent(() => import('./HeaderUserSlice/index.vue')),
    links_column_slice: defineAsyncComponent(() => import('./LinksColumnSlice/index.vue')),
    malt_footer_info_slice: defineAsyncComponent(() => import('./MaltFooterInfoSlice/index.vue')),
    megamenu: defineAsyncComponent(() => import('./Megamenu/index.vue')),
    megamenu_subcategory: defineAsyncComponent(() => import('./MegamenuSubcategory/index.vue')),
    user_settings_slice: defineAsyncComponent(() => import('./UserSettingsSlice/index.vue')),
});
